<form [formGroup]="formInput" nz-form>
  <div class="bottom20 flex-space-between">
    <div class="medium">{{formTitle}}</div>
    <div>
      <nz-radio-group (ngModelChange)="onChangeLocationType($event)" [formControlName]="'locationType'">
        <label nz-radio-button nzValue="specific">Specific</label>
        <label nz-radio-button nzValue="customize">Customize</label>
      </nz-radio-group>
    </div>
      </div>
  <div *ngIf="locationType === SPECIFIC" class="bottom20">
    <div class="form-label-v2">Location Name</div>
    <nz-select
      nzBackdrop="true"
      (ngModelChange)="onInputItemChanged('warehouseId', $event)"
      nzShowSearch nzAllowClear
      [formControlName]="'warehouseId'"
      style="width: 100%;"
    >
      <nz-option *ngFor="let location of listLocations" [nzLabel]="location.name" [nzValue]="location.id"></nz-option>
    </nz-select>
    <div *ngIf="selectedLocatname" class="location-name">
      <p>{{ selectedLocatname }}</p>
    </div>
  </div>

  <div *ngIf="locationType === CUSTOMIZE" class="bottom20">
    <div class="bottom20">
      <div class="form-label-v2">State</div>
      <nz-select nzBackdrop="true" style="width: 100%;" nzShowSearch nzAllowClear
        [nzPlaceHolder]="getPlaceHolder('state')" [formControlName]="'state'"
        nzDropdownClassName="dispatch-filter-dispatcher"
        (ngModelChange)="onDeliveryStateChanged('state')">
        <nz-option-group *ngFor="let country of countriesStates" [nzLabel]="country.name">
          <nz-option
            *ngFor="let state of country.states"
            nzValue="{{country.code}}_{{state.code}}"
            [nzLabel]="getStateDesc(state)"
          ></nz-option>
        </nz-option-group>
      </nz-select>
    </div>
    <div class="bottom20">
      <div class="form-label-v2">Zipcode</div>
      <div zipcode-selector placeholder="Zipcode" [size]="'sm'" [initZipcode]="formInput.get('zipcode').value" 
        (onNewValue)="onZipcodeChange('zipcode', $event)" (onSearching)="onSearchZipcode('zipcode', $event)">
      </div>
    </div>
  </div>
  </form>
