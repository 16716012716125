import { Component, OnInit, Output, EventEmitter, Input, Renderer2, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ZipcodeSelectorValue } from '@app/admin/components/common/zipcode-selector';
import { MasterData } from '@services/master.data';
import { BaseFormItem } from '../form-item';
import { Const } from '@const/Const';
import { Log } from '@services/log';

@Component({
  selector: 'location-filter',
  templateUrl: './location-filter.component.html',
  styleUrls: [
    '../../../../styles/form-v2.scss',
    './location-filter.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationFilterComponent extends BaseFormItem implements OnInit, OnDestroy, AfterViewInit {
  @Input() formTitle: string;
  private _isClear: boolean = false;
  @Input() set isClear(value) {
    this._isClear = value;
    if (this.formInput) {
      setTimeout(() => {
        this.formInput.get('warehouseId').setValue(null);
        this.formInput.get('zipcode').setValue(null);
        this.formInput.get('state').setValue(null);
        this.selectedLocatname = null;
        this
      }, 1000);
    }
  };
  get isClear(): boolean {
    return this._isClear;
  }
  @Output() onValueChanged = new EventEmitter<any>();

  listLocations = []; // Populate this with actual data
  listStates = []; // Populate this with actual data
  state: any;
  selectedTabIndex = 0;
  selectedLocatname = null;

  readonly CUSTOMIZE = 'customize';
  readonly SPECIFIC = 'specific';
  locationType = this.SPECIFIC;

  private get declaration(): FormGroupDeclaration {return {
    locationType: {label: 'Location Type', notAcceptEmpty: true, initialValue: this.SPECIFIC},
    warehouseId: {label: 'Warehouse', notAcceptEmpty: true},
    state: {label: 'State', placeHolder: 'Select', notAcceptEmpty: true},
    zipcode: {label: 'Zipcode', placeHolder: 'From Zipcode', notAcceptEmpty: true},
  }}

  public countriesStates = MasterData.getCountriesStates_forSelectGroup();
  protected formGroupDeclaration: FormGroupDeclaration = this.declaration ?? {};

  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getListWarehouse();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges(); // Ensure change detection is triggered after view initialization
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected beforeBindModel(model) {
    if (model?.warehouseId) {
      model.locationType = this.SPECIFIC;
      this.locationType = this.SPECIFIC;
    }
    if (model?.state || model?.zipcode) {
      model.locationType = this.CUSTOMIZE;
      this.locationType = this.CUSTOMIZE;
    }
    return model;
  }

  protected afterBindModel(model) {
    if (model?.warehouseId) {
      this.onGetLocationDetail(model.warehouseId);
    }
  }

  public isSearchZipcode = {
    pickupZipcode: false,
    dropoffZipcode: false
  }

  onZipcodeChange(key, value: ZipcodeSelectorValue) {
    this.formInput.patchValue({ [key]: value?.zipcode });
    this.onValueChanged.emit({key: 'zipcode', value: this.formInput.value});
  }

  onSearchZipcode(key: string, isSearching: boolean) {
    this.isSearchZipcode[key] = isSearching;
  }

  onDeliveryStateChanged(state) {
    this.state = state;
    this.onValueChanged.emit({key: 'state', value: this.formInput.value});
  }

  private getListWarehouse() {
    let url = `${Const.APIURI_WAREHOUSES}/list/all_for_filter`
    this.api.GET(url).subscribe(
      resp => {
        this.listLocations = resp?.data?.list_data ?? [];
        this.cdr.detectChanges();
      }, err => {
        Log.e(err);
      }
    )
  }

  onInputItemChanged(key: string, value: any) {
    if (key === 'warehouseId') {
      if (value) {
        this.onGetLocationDetail(value);
      } else {
        this.selectedLocatname = null;
      }
    }
    this.onValueChanged.emit({key, value: this.formInput.value});
  }

  onChangeLocationType(value) {
    this.locationType = value;
  }

  onGetLocationDetail(id) {
    const url = `${Const.APIURI_WAREHOUSES}/${id}`;
    this.api.GET(url).subscribe(
      resp => {
        if (resp?.data?.pickAddr) {
          this.selectedLocatname = this.getAddressText(resp?.data?.pickAddr);
        } else {
          this.selectedLocatname = null;
        }
        this.cdr.detectChanges(); // Manually trigger change detection
      }, err => {
        Log.e(err);
      }
    )
  }
}
