import { ViewChild, Directive, HostListener } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { KeyLang } from '@locale/generated/keys';
import { Utils } from '@services/utils';
import { getInjector } from '@services/index';
import { Log } from '@services/log';
import { Const } from '@app/const/Const';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { PaginationData } from '@app/model/PaginationData';
import { PaginationData as PaginationDataInterface } from "@wearewarp/types/common/interface";
import { Subscription } from 'rxjs';
import { DialogService } from '@dialogs/dialog.service';
import { ModalOptions } from 'ng-zorro-antd/modal';
import { NzDrawerOptions } from 'ng-zorro-antd/drawer';
import { ListFilterDialog } from './list-filter-dialog';
import { DrawerService } from '@app/drawers/drawer.service';
import { ListFilterDrawer } from './list-filter-drawer';

@Directive()
export abstract class BaseList<T = any> extends BaseComponent {

  apiVersion = 'v1';
  txtHintSearch = '';

  selectAll = false;
  selectedItems = [];

  public searchKeyword = '';
  public paginationData: PaginationData<T> = new PaginationData<T>();
  
  public isLastPage = false;
  get currentPage(): number { return Utils.toNumber((this.queryParams || {}).page, 1); }
  get searchFromQuery(): string { return (this.queryParams || {}).search || '' }
  get listData(): Array<T> { return this.paginationData ? this.paginationData.list_data : [] }
  get totalCount(): number { return this.paginationData ? this.paginationData.total : 0 }
  get skip(): number {
    return this.limit < 0 ? 0 : (this.currentPage - 1) * this.limit;
  }
  get currentPageSize(): number { return Utils.toNumber((this.queryParams || {}).limit, Const.PAGINATION_LIMIT); }
  get limit(): number { return this.currentPageSize; }
  pageCount: number = 0;
  public pageIndex = 1; // for those who use nz-pagination without nz-table
  isLoading = false;

  // public sortOptions: Array<any> = [
  //   {
  //     key: KeyLang.Txt_Name,
  //     text: '',
  //     param: 'name',
  //     value: 1
  //   },
  //   // {
  //   //   key: KeyLang.Txt_Code,
  //   //   text: '',
  //   //   param: 'code',
  //   //   value: 1
  //   // },
  //   {
  //     key: KeyLang.Txt_CreateAt,
  //     text: '',
  //     param: 'insert.when',
  //     value: -1
  //   },
  //   {
  //     key: KeyLang.Txt_UpdateAt,
  //     text: '',
  //     param: 'update.when',
  //     value: -1,
  //     isDefault: true
  //   }
  // ];
  // public currentSortOption;
  protected subRouteChanged;
  protected subApi: Subscription;

  
  @ViewChild('nzTable', {static: false}) nzTable: NzTableComponent<any>;
  
  constructor() {
    super();
    let injector = getInjector();
    // for (let item of this.sortOptions) {
    //   if (item.isDefault) {
    //     this.currentSortOption = item;
    //     break;
    //   }
    // }
    // if (!this.currentSortOption) {
    //   this.currentSortOption = this.sortOptions[0];
    // }
  }

  ngOnInit() {
    super.ngOnInit();
  }
  
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (!event.ctrlKey) {
      return;
    }
    switch (event.key) {
      case 'R':
      case 'r':
        this.onBtnRefresh();
        break;
      // case 'N':
      // case 'n':
      //   this.onBtnAdd();
      //   break;
    }
  }
  
  protected getPageTitle(): string {
    return '';
  }
  
  protected needReload(prevQueryParam) {
    return this.listData.length == 0 || !Utils.compareObject(this.queryParams, prevQueryParam);
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any) {
    this.pageIndex = Utils.toNumber(this.queryParams.page, 1);
    this.searchKeyword = this.queryParams.search || '';
    if (this.needReload(prevQueryParam)) {
      this.selectedItems = [];
      this.getData();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subRouteChanged) {
      this.subRouteChanged.unsubscribe();
    }
    this.subApi?.unsubscribe();
  }
  
  protected abstract getApiUrl(): string;

  protected onGetDataSucceeded(resp) {
  }

  protected setupLanguage() {
    this.txtHintSearch = this.text(KeyLang.Txt_HintSearch);
    // for (const op of this.sortOptions) {
    //   if (op.key) {
    //     op.text = this.text(op.key);
    //   }
    // }
  }

  // get txtLabelSortOptions() {
  //   return this.currentSortOption ? this.currentSortOption.text : 'Sort';
  // }

  get txtSum(): string {
    return this.text(KeyLang.Txt_ListSum, [this.totalCount.toString(), this.pageCount.toString()]);
  }

  // onSelectAll(value) {
  //   this.selectedItems = [];
  //   for (let i = 0; i < this.listData.length; i++) {
  //     this.listData[i].selected = value;
  //     if (value) {
  //       this.selectedItems.push(this.listData[i]);
  //     }
  //   }
  // }

  // onItemSelect(itemModel, checked) {
  //   if (checked) {
  //     this.selectedItems.push(itemModel);  
  //   } else {
  //     this.selectedItems = this.selectedItems.filter(item => this.getItemId(item) != this.getItemId(itemModel));
  //   }
  //   if (this.selectedItems.length == 0 && this.selectAll) {
  //     this.selectAll = false;
  //   } else if (this.selectedItems.length == this.listData.length && !this.selectAll) {
  //     this.selectAll = true;
  //   }
  // }

  // onBtnMore(event) {
  //   // if (this.menuMore && this.menuMoreItems.length > 0) {
  //   //   this.menuMore.shouldAbsulutePosition = false;
  //   //   this.menuMore.toggle(event);
  //   //   const headerMenuMore = document.getElementById('headerMenuMore');
  //   //   const elemMenu = headerMenuMore.getElementsByClassName('ui-tieredmenu')[0] as HTMLElement;
  //   //   elemMenu.style.cssText = elemMenu.style.cssText + 'width: auto;';
  //   // }
  // }

  onBtnRefresh() {
    this.selectedItems = [];
    this.getData();
  }

  onBtnAdd() {
    let url = this.router.url.split('?')[0];
    this.router.navigate([url, 'add']);
  }

  // onSortOptionsClick(option) {
  //   this.routeWithQueryUrl({sort: this.sortOptionString()});
  // }

  // private sortOptionString() {
  //   if (!this.currentSortOption) return '';
  //   return `${this.currentSortOption.param},${this.currentSortOption.value}`
  // }

  // use sort function of nz-table
  onSortOrderChange(event) {
    let query = Utils.cloneObject(this.queryParams);
    delete query.sort;
    switch (event.value) {
      case 'ascend':
        query.sort = `${event.key},1`;
        break;
      case 'descend':
        query.sort = `${event.key},-1`;
        break;
      default:
        break;
    }
    this.routeWithQueryUrl(query, true);
  }

  doSearch(keyword) {
    this.routeWithQueryUrl({page: 1, search: keyword});
  }

  onDataListPageChanged(page) {
    this.selectAll = false;
    this.routeWithQueryUrl({page: page});
  }
  onDataListPageSizeChanged(pageSize){
    this.selectAll = false;
    this.routeWithQueryUrl({limit: pageSize})
  }

  // Hàm này để class kế thừa có thể customize giá trị của parameter.
  // Nếu ko override thì sẽ lấy nguyên giá trị user nhập ở url.
  protected getParamValue(key) {
    return this.queryParams[key];
  }

  protected prepareParamGetList() {
    let params: any = {};
    params.skip = this.skip;
    params.limit = this.limit;
    if (this.queryParams.sort) {
      params.sort = this.queryParams.sort;
    }
    if (this.queryParams.search) {
      params.search = this.queryParams.search;
    }
    if (this.queryParams.filter) {
      params.filter = this.queryParams.filter;
    }
    return params;
  }

  protected getItemId(item) {
    return item._id;
  }

  protected getData() {
    let apiUrl = this.getApiUrl();
    if (!apiUrl) {
      Log.e(`[${this.TAG}] getData but apiUrl is empty`);
      return;
    }
    let params = this.prepareParamGetList();
    let qs = new URLSearchParams(params).toString();
    if (apiUrl.indexOf('?') === -1) {
      apiUrl += '?';
    } else {
      apiUrl += '&';
    }
    apiUrl += qs;
    this.getDataByUrl(this.api.buildUrl(apiUrl, this.apiVersion));
  }
  
  protected getDataByUrl(url: string) {
    this.isLoading = true;
    this.subApi?.unsubscribe();
    this.subApi = this.api.GET(url).subscribe(
      resp => {
        Log.d('get list data done: ', resp);
        this.getDataDone(resp);
        this.isLoading = false
      }, err => {
        this.showErr(err);
        this.isLoading = false
      }
    );
  }

  protected setPaginationData(data: PaginationDataInterface<T>) {
    this.paginationData.setData(data);
    if (this.limit > 0) {
      this.pageCount = Math.ceil(this.totalCount / this.limit);
    } else {
      this.pageCount = 1;
    }
    let page = Utils.toNumber(this.queryParams.page, 1);
    if (this.nzTable && this.nzTable.nzPageIndex != page) {
      this.nzTable.nzPageIndex = page;
    }
    this.isLastPage = this.listData.length < this.limit;
  }

  protected getDataDone(resp) {
    this.setPaginationData(resp.data);
    this.onGetDataSucceeded(resp);
  }

  // for editing directly on table's row (don't need to go to detail page)

  public onBtnEditItem(item) {
    item.editting = true;
  }

  public onBtnSaveItem(item) {
    item.loading = true;
    let keys = Object.keys(item.tmp);
    let params = {};
    for (let key of keys) {
      if (item[key] != item.tmp[key]) {
        params[key] = item.tmp[key]
        if (Utils.isString(params[key])) {
          params[key] = params[key].trim();
        }
      }
    }
    let url = `${this.getApiUrl()}/${this.getItemId(item)}`;
    this.api.PUT(url, params).subscribe(
      resp => {
        this.onUpdateItemSuccess(resp.data);
      }, err => {
        item.loading = false;
        this.showErr(err);
      }
    );
  }
  
  protected onUpdateItemSuccess(newItem) {
    for (let i = 0; i < this.listData.length; i++) {
      if (this.getItemId(this.listData[i]) == this.getItemId(newItem)) {
        this.listData[i] = newItem;
        break;
      }
    }
  }

  protected confirmDeleteMessage(item): string {
    return `Delete <b>${this.getItemName(item)}</b>?`;
  }

  protected notifyItemDeletedMessage(item): string {
    return this.text(KeyLang.Txt_ItemDeleted, `<b>${this.getItemName(item)}</b>`)
  }

  // Dùng khi confirm delete, hiện thông báo khi delete xong
  protected getItemName(item): string {
    return item?.name;
  }

  public onBtnDelItem(item) {
    this.confirmDeletion({
      message: this.confirmDeleteMessage(item),
      fnOk: () => {
        this.deleteItem(item);
      }
    });
  }

  protected deleteItem(item) {
    item.loading = true;
    let url = `${this.getApiUrl()}/${this.getItemId(item)}`;
    this.api.DELETE(this.api.buildUrl(url)).subscribe(
      resp => {
        this.showSuccess(this.notifyItemDeletedMessage(item));
        this.getData();
      }, err => {
        this.showErr(err);
        item.loading = false;
      }
    );
  }

  public onBtnCancelItem(item) {
    item.editting = false;
  }

  public canSaveItem(item) {
    if (!item || !item.tmp || item.loading) return false;
    let keys = Object.keys(item.tmp);
    for (let key of keys) {
      if (item[key] != item.tmp[key]) {
        return true;
      }
    }
    return false;
  }

  public canCancelItem(item) {
    return !item.loading;
  }

  // for filter dialog
  public get hasFilter(): boolean { return !!this.queryParams.filter }
  protected get filterMetadata() {return this.paginationData.filterMetadata}
  protected set filterMetadata(value) {this.paginationData.filterMetadata = value}  

  protected getDialogFilterClass(): {class: any, params?: any} {
    return null;
  }

  public onBtnFilter( type: 'drawer' | 'dialog' = 'dialog') {
    let currentFilter;
    try {
      currentFilter = JSON.parse(this.queryParams.filter)
    } catch (e) {}
    let dialog = this.getDialogFilterClass();
    if (!dialog) {
      return this.comingSoon();
    }
    this.openFilter({filterClass: dialog, type, currentFilter});
  }

  public openFilter(options: {type?: 'drawer' | 'dialog', filterClass: {class: any, params?: any}, currentFilter?: any, fnFilter?: (condition, metadata) => void}) {
    let fnFilter = typeof options.fnFilter == 'function' ? options.fnFilter : this.doFilter.bind(this);
    let filterClass = options.filterClass;
    let type = options.type ?? 'drawer';
    if (type == 'drawer') {
      let ops: NzDrawerOptions<ListFilterDrawer> = {
        nzContentParams: {
          doFilter: fnFilter,
          model: options.currentFilter,
          closeOnSuccess: true,
        },
      };
      if (Utils.isObjectNotEmpty(filterClass.params)) {
        ops.nzContentParams = Object.assign(ops.nzContentParams, filterClass.params);
      }
      DrawerService.openFormDrawer(filterClass.class, ops);
    } else {
      // Mặc định hiện filter dưới modal
      let ops: ModalOptions<ListFilterDialog> = {
        nzComponentParams: {
          doFilter: fnFilter,
          model: options.currentFilter,
          closeOnSuccess: true,
        },
        nzClassName: 'modal-no-padding',
        nzCentered: true,
      };
      if (Utils.isObjectNotEmpty(filterClass.params)) {
        ops.nzComponentParams = Object.assign(ops.nzComponentParams, filterClass.params);
      }
      DialogService.openFormDialog1(filterClass.class, ops);
    }
  }

  private doFilter(condition, metadata) {
    this.filterMetadata = metadata;
    let query = Utils.cloneObject(this.queryParams);
    if (Utils.isObjectNotEmpty(condition)) {
      // apply filter
      query.filter = JSON.stringify(condition);
      query.page = 1;
    } else {
      // clear filter
      delete query.filter;
      query.page = 1;
    }
    this.routeWithQueryUrl(query, true);
  }
}